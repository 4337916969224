import React from 'react'
import Navbar from '../inc/Navbar'
import ClientReviews from './ClientReviews'
import {Link} from "react-router-dom";

 
function Reviewpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='shyamolyfincare'>
        <div className="album py-1">

          <div className="container mt-3">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Reviews</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <ClientReviews/>
      <br/>
    </div>
  )
}

export default Reviewpage