import React, { useState, useEffect } from 'react';

import { useNavigate,Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';






function Govtholder(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(40);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [gallery, setGallery] = useState([])
  const navigate = useNavigate()

  const galleryCollectionRef = collection(db, "Default Govt Employee Loan");
  useEffect(() => {

    const getGallery = async () => {
      const data = await getDocs(galleryCollectionRef);
      setGallery(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getGallery()
  }, [])
  return (

    <>
<Navbar/>
<br/>
<br/>
<br/>
<br/>
 <div className="homeloannadia">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Default Govt. Employee Loan
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>






      <div className="album py-3">

        <div className="container">

          <div className="row">
            {gallery.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchTerm.toLowerCase())





              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((shyamolyfincare) => {
              return (

                <div className="col-md-3 mt-2" >
                  <div className="card mb-4">
                    <img className="card-img-top" src={shyamolyfincare.img} alt={shyamolyfincare.title} />
                    <div className="card-body">
                      <center>
                        <h6 style={{ color: "Darkblue" }} className="card-text"> <b>{shyamolyfincare.title} </b></h6>
                      </center>




                      <div className="d-flex justify-content-between align-items-center">

                    




                      </div>
                    </div>
                  </div>

                </div>
              )
            })}
          </div>
      
        </div>
      </div>


    </>

  );
}

export default Govtholder;