import React from 'react'
import ReactPlayer from "react-player";
function ClientReviews() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/shorts/tNCZbaTpPgk" />
                                <div className="card-body">
                                    <div className='coloradd'>
                                        <h5 className="card-text mt-2 mb-1"> <b>Our Customer Reviews</b></h5>
                                    </div>
                             

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/shorts/gKXfDTcgvDE" />
                                <div className="card-body">
                                    <div className='coloradd'>
                                        <h5 className="card-text mt-2 mb-1"> <b>Our Customer Reviews</b></h5>
                                    </div>
                              

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/shorts/VKdAQcg54yQ"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                        <h5 className="card-text mt-2 mb-1"> <b>Our Customer Reviews</b></h5>
                                    </div>
                                  


                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ClientReviews