import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contact from './components/pages/Contact';
import About from './components/pages/About';

import HomeLoan from './components/pages/HomeLoan';
import LandPloan from './components/pages/LandPloan';
import BusinessLoan from './components/pages/BusinessLoan';
import LoanApply from './components/pages/LoanApply';
import MortgageLoan from './components/pages/MortgageLoan';
import AnimalFarmLoan from './components/pages/AnimalFarmLoan';
import BtLoan from './components/pages/BtLoan';
import Ccloan from './components/pages/Ccloan';
import Cowfarming from './components/pages/Cowfarming';

import Certificatepage from './components/pages/Certificate';
import Govtholder from './components/pages/Govtholder';
import Reviewpage from './components/pages/Reviewpage';



function App() {
  return (
    <Router>
    <div>
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      <Route axact path="/About" element={<About/>}/>
      <Route axact path="/Certificate" element={<Certificatepage/>}/>
      <Route axact path="/Home-loan" element={<HomeLoan/>}/>
      <Route axact path="/land-purchase-loan" element={<LandPloan/>}/>
      <Route axact path="/business-loan" element={<BusinessLoan/>}/>
      <Route axact path="/applyloan" element={<LoanApply/>}/>
      <Route axact path="/mortgage-loan" element={<MortgageLoan/>}/>
      <Route axact path="/animal-farming-loan" element={<AnimalFarmLoan/>}/>
      <Route axact path="/bttopup-loan" element={<BtLoan/>}/>
      <Route axact path="/cc-loan" element={<Ccloan/>}/>
      <Route axact path="/Cowfarming" element={<Cowfarming/>}/>
      <Route axact path="/Govt-holder" element={<Govtholder/>}/>
      <Route axact path="/reviews" element={<Reviewpage/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
