import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import animalfarming from "../images/ANIMAL FARMING.jpg"
import animalfarmingloan from "../images/ANIMAL FARMING LOAN.jpg";
import Poltry from './Poltry';

function AnimalFarmLoan() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />


      <ScrollToTop smooth top="100" color="maroon" />

      <div className="homeloannadia">
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Animal Farming loan</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mt-3">
              <div className="card">

                <div className="card-body">
                  <h5 className="card-title">ANIMAL FARMING LOAN</h5>
                  <p>Investing in livestock and animal farming? Our specialized animal farming loans provide financial support to start or expand your livestock business. Whether you're raising cattle, poultry, or other animals, our loans offer competitive rates and flexible terms to meet your agricultural needs.</p>

                  <p className="card-text">1. Benefits of Animal Farming Loan</p>
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "goldenrod" }} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Flexible financing options tailored to livestock businesses.
                  </small></p>
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "goldenrod" }} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg>  Competitive interest rates with repayment schedules that fit seasonal cash flows.
                  </small></p>
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Expert guidance from agricultural specialists familiar with animal farming.
                  </small></p>

                  <p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Access to additional resources and networking opportunities in the agricultural community.
                  </small></p>



                  <p className="card-text mb-2"><b>2. Eligibility and Criteria</b></p>
                  <p className='mb-4'>Our animal farming loans are available to farmers and ranchers with a demonstrated history in animal husbandry. Contact us to discuss eligibility requirements and how we can assist in achieving your livestock farming goals.</p>


                  <p className="card-text mb-2"><b>3. Guide to Animal Farming Loans</b></p>
                  <p>Explore our comprehensive guide to animal farming loans, covering topics such as best practices in livestock management, financial planning, and maximizing profitability in animal agriculture.</p>
                  <a href="applyloan" className="buttonapply">Apply Now</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={animalfarming} className="card-img-top mb-2" alt="animalfarming" />
                <img src={animalfarmingloan} className="card-img-top mb-2" alt="animalfarming" />

                <div className="card-body">
                  <p className="card-title"><a href='tel: 9531781475'>Help Line No. +91 9531781475</a></p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <Poltry/>
      <br />
    </div>
  )
}

export default AnimalFarmLoan